import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { TeCommon } from "../Common";
import { InstructionsModel } from "@/models";

export class InputForm {
    iid = '';
    content = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherlnstructionsIndexController>({
    components: {
        Header,Footer
    }
})
export default class ApTeacherlnstructionsIndexController extends Vue {
    private inputForm = new InputForm();
    private token = window.localStorage.getItem('teacherToken') as string;

    public async created() {
        const item: { [key: string]: string } = {
            iid: '1',
            token: this.token,
        }
        const data = await InstructionsModel.teGet(item) as response;
        this.assignDataToForm(data);
    }

    private assignDataToForm(data: response) {
        TeCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as { [key: string]: string };

        this.inputForm.iid = item.iid;
        this.inputForm.content = item.content;
    }
}